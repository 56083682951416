<template lang="pug">
div
  loading(:active.sync="busy", :is-full-page="true")
  .widget
    .widget-header
      h1.title Tracking Link Settings
    .widget-body
      p This settings will be applied to all offers.
      p Allowed characters in tracking link parameters are: a-z, A-Z, 0-9, -, _. Should be enclosed in curly braces.
      form.form(@submit.prevent="submit")
        .row
          .col-lg-3.col-md-4.col-sm-6
            validated-input(label="aff_clickid", :value="getUrlParam('aff_clickid')", @input="updateUrlParam('aff_clickid', $event)", :is-valid="overrideTrackingParamsValidityMap.aff_clickid", :error-message="overrideTrackingParamsValidityErrorMessageMap.aff_clickid")
          .col-lg-3.col-md-4.col-sm-6
            validated-input(label="sub1", :value="getUrlParam('sub1')", @input="updateUrlParam('sub1', $event)", :is-valid="overrideTrackingParamsValidityMap.sub1", :error-message="overrideTrackingParamsValidityErrorMessageMap.sub1")  
          .col-lg-3.col-md-4.col-sm-6
            validated-input(label="sub2", :value="getUrlParam('sub2')", @input="updateUrlParam('sub2', $event)", :is-valid="overrideTrackingParamsValidityMap.sub2", :error-message="overrideTrackingParamsValidityErrorMessageMap.sub2")
          .col-lg-3.col-md-4.col-sm-6
            validated-input(label="sub3", :value="getUrlParam('sub3')", @input="updateUrlParam('sub3', $event)", :is-valid="overrideTrackingParamsValidityMap.sub3", :error-message="overrideTrackingParamsValidityErrorMessageMap.sub3")
          .col-lg-3.col-md-4.col-sm-6
            validated-input(label="app_name", :value="getUrlParam('app_name')", @input="updateUrlParam('app_name', $event)", :is-valid="overrideTrackingParamsValidityMap.app_name", :error-message="overrideTrackingParamsValidityErrorMessageMap.app_name")
          .col-lg-3.col-md-4.col-sm-6
            validated-input(label="bundle_id", :value="getUrlParam('bundle_id')", @input="updateUrlParam('bundle_id', $event)", :is-valid="overrideTrackingParamsValidityMap.bundle_id", :error-message="overrideTrackingParamsValidityErrorMessageMap.bundle_id")
          .col-lg-3.col-md-4.col-sm-6(v-b-tooltip.hover.bottom, title="iOS only")
            validated-input(label="idfa", :value="getUrlParam('idfa')", @input="updateUrlParam('idfa', $event)", :is-valid="overrideTrackingParamsValidityMap.idfa", :error-message="overrideTrackingParamsValidityErrorMessageMap.idfa")
          .col-lg-3.col-md-4.col-sm-6(v-b-tooltip.hover.bottom, title="Android only")
            validated-input(label="gaid", :value="getUrlParam('gaid')", @input="updateUrlParam('gaid', $event)", :is-valid="overrideTrackingParamsValidityMap.gaid", :error-message="overrideTrackingParamsValidityErrorMessageMap.gaid")
        button.btn.btn-primary(type="submit") Save
          i.la.la-fw.la-save
</template>
<script>

export default {
  name: 'PublisherSettings',
  computed: {
    overrideTrackingParams: {
      get() {
        return this.$store.state.user.overrideTrackingParams;
      },
    },
  },
  data() {
    return {
      busy: false,

      urlParams: {
        aff_clickid: '{aff_clickid}',
        idfa: '{idfa}',
        gaid: '{gaid}',
        sub1: '{sub1}',
        sub2: '{sub2}',
        sub3: '{sub3}',
        app_name: '{app_name}',
        bundle_id: '{bundle_id}',
      },
      overrideTrackingParamsValidityMap: {
        aff_clickid: true,
        idfa: true,
        gaid: true,
        sub1: true,
        sub2: true,
        sub3: true,
        app_name: true,
        bundle_id: true,
      },
      overrideTrackingParamsValidityErrorMessageMap: {
        aff_clickid: '',
        idfa: '',
        gaid: '',
        sub1: '',
        sub2: '',
        sub3: '',
        app_name: '',
        bundle_id: '',
      },
    };
  },
  mounted() {
    this.revalidateUrlParams();
  },
  methods: {
    /** @param {string} key */
    getUrlParam(key) {
      const override = this.overrideTrackingParams[key];
      if (override === undefined) return this.urlParams[key]
      return override;
    },
    /** @param {string} key @param {string} value */
    updateUrlParam(key, value) {
      this.urlParams[key] = value;
      if (key === value) {
        delete this.overrideTrackingParams[key];
      } else {
        this.overrideTrackingParams[key] = value;
      }
      this.revalidateUrlParams();
    },
    /** @param {string} key */
    validateUrlParam(key) {
      const regex = new RegExp(/(^{[a-zA-Z0-9_-]+}$)|(^$)/);
      const value = this.getUrlParam(key);

      if (key === 'aff_clickid' && !value) {
        this.overrideTrackingParamsValidityErrorMessageMap[key] = 'Affiliate Click ID is mandatory';
        return false;
      }

      const result = regex.test(value);
      this.overrideTrackingParamsValidityErrorMessageMap[key] = result ? '' : 'Invalid URL parameter';
      return result;
    },
    revalidateUrlParams() {
      this.overrideTrackingParamsValidityMap = {
        aff_clickid: this.validateUrlParam('aff_clickid'),
        idfa: this.validateUrlParam('idfa'),
        gaid: this.validateUrlParam('gaid'),
        sub1: this.validateUrlParam('sub1'),
        sub2: this.validateUrlParam('sub2'),
        sub3: this.validateUrlParam('sub3'),
        app_name: this.validateUrlParam('app_name'),
        bundle_id: this.validateUrlParam('bundle_id'),
      };
    },

    async submit() {
      if (this.busy) {
        return;
      }

      const allTrackingParamsValid = Object.values(this.overrideTrackingParamsValidityMap).every((v) => v);
      if (!allTrackingParamsValid) {
        return this.$ovNotify.error('Please check all tracking link parameters');
      }

      const data = { override_tracking_params: this.overrideTrackingParams, }
      this.busy = true;

      try {
        const response = await this.$ovReq.post('publishers/save', data);
        if (response.err === 1) {
          this.$ovNotify.error(response.errdesc);
        }
        this.$ovNotify.success('Params have been saved');
      } catch (e) {
        this.$ovNotify.error(e);
      } finally {
        this.busy = false;
      }
    },
  },
}
</script>
